@import url(https://fonts.googleapis.com/css2?family=Nova+Round&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
    font-family: 'Nova Round', cursive;
}

/* Marketplace Nav */

.mrk-nav {
    position: relative;
    
    height: 2%;
    width: 100%;
    border-bottom: rgb(211, 211, 211) solid 1px;
}

.mrk-nav p {
    position: relative;
    text-align: center;
    margin-left: 2vw;
}

.mrk-nav a {
    font-size: 1.2rem;
    text-decoration: none;
    border-bottom: solid 3px rgb(47, 44, 255);
}

.txt-logo {
    background: linear-gradient(to right, #CF5BB4 0%, #AE21FF 50%, #5C0AFF 51%, #769DCF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Marketplace header */

.mrk-header {
    width: 100%;
    max-width: 1500px;
    color: #333;
    display: flex;
    text-align: center;
    margin: auto;
    height: 100%;
}

.mrk-header-left {
    margin-top: 4vh;
    position: relative;
    margin-left: auto;
}

.mrk-header-left img {
    width: 18rem;
}

.mrk-header-explorer {
    margin-top: 4vh;
    margin-right: auto;
    position: relative;
    width: 20%;
    margin-left: 2vw;
    margin-right: auto;
    text-align: left;
    display: block;
    height: 100%;
    border: 1px solid #d4d4d4;
    border-radius: 20px;
}

.mrk-header-explorer ul {
    list-style-type: none;
}

.expl-it {
    margin-bottom: 5px;
}

.mrk-header-explorer ul li a {
    text-decoration: none;
    color: #686868;
}

.expl-head {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
    background: linear-gradient(to right, rgb(255, 0, 200), rgb(179, 87, 255));
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.expl-head span {
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
}

/* Marketplace body */

.mrk-body {
    margin-top: 4vh;
    margin-left: 2vw;
    margin-right: 2vw;
}

.mrk-body-showcase {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(200px, auto);
    /*background: rgb(129, 226, 255);
    */
    border-top: 1px solid rgb(211, 211, 211);
    border-bottom: 1px solid rgb(211, 211, 211);

    max-width: 1500px;
    margin: 0 auto;
}

/* Marketplace saNFT card*/

.sa-nft {
    
    border-radius: 20px;
    text-align: center;
    width: 95%;
    height: 100%;
    margin: auto;
}

.sa-nft-info {
    display: inline-block;
    line-height: 0;
    text-align: left;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    color: #888888;
    padding-bottom: 15px;
}

.sa-nft-body {
    display: block;
    height: 100%;
    margin-bottom: 5px;
}

.sa-nft-body img {
    margin-top: 5px;
    width: 240px;
    height: 240px;
    border-radius: 10px;
}

.sa-nft-header h2 {
    font-size: 18px;
}

.name-info  {
    line-height: 0;
}
